import axios, { AxiosResponse } from 'axios';
import { Branch } from 'store/space/type';
import { Cookie, CookieSetOptions } from 'universal-cookie';
import moment from 'moment';
import { BANK_CODE, BANK_NAME } from '../constants';

// Cookie 문자열을 Object로 변한
export const cookieStringToObject = (cookieString: string | undefined) => {
  const cookies: { [key: string]: string } = {};
  if (cookieString) {
    //* "token=value"
    const itemString = cookieString?.split(/\s*;\s*/);
    itemString.forEach(pairs => {
      //* ["token","value"]
      const pair = pairs.split(/\s*=\s*/);
      cookies[pair[0]] = pair.splice(1).join('=');
    });
  }
  return cookies;
};

// API 에러 메시지
export const getAPIErrorMessage = (response: AxiosResponse) => {
  const key = Object.keys(response.data)[0];
  if (key === 'detail') {
    return response.data[key];
  }
  if (typeof response.data[key] === 'object') {
    const sub = Object.keys(response.data[key])[0];
    return response.data[key][sub];
  }
  return response.data[key][0];
};

// 빈 자리수 0으로 채워넣기
// example) 1 > 01
// example) 9 > 09
export const digitString = (n: number, digits: number) => {
  let zero = '';
  const str = n.toString();

  if (str.length < digits) {
    for (let i = 0; i < digits - str.length; i++) zero += '0';
  }
  return zero + str;
};

// Branch ID로 Branch 이름 얻기
export const getBranchNameByID = (branches: Branch[], id: number) => {
  return branches.filter(branch => branch.id === id)[0].name;
};

// Axios Config
// Authorization 헤더 만들어주기
export const makeAuthorizationConfig = (token: string) => {
  return {
    headers: { Authorization: `Bearer ${token}` },
  };
};

// Local Stitch Cookie Setting
// Access Token (1일) & Refresh Token (14일)
export const setLocalStitchCookie = (
  setCookie: (name: string, value: Cookie, option?: CookieSetOptions) => void,
  access: string,
  refresh: string,
) => {
  const option: CookieSetOptions = {
    path: '/',
    maxAge: 60 * 60 * 24,
  };

  setCookie('access_token', access, option);
  option.maxAge = 60 * 60 * 24 * 14;
  setCookie('refresh_token', refresh, option);
};

export const getBankNameByCode = (code: string) => {
  return BANK_NAME[code];
};

export const getClassNameByCode = (code: string) => {
  return Object.keys(BANK_CODE)
    .filter(key => {
      if (BANK_CODE[key] === code) return key;
    })[0]
    .toLowerCase();
};

export const viewAccount = (account: string) => {
  const first = account.slice(0, 4);
  const second = account.slice(4, 8);
  const middle = account.slice(8, 10);
  const end = account.slice(10, 14);

  const result = [first, second, middle, end];
  return result.join('-');
};

export const calculateTimeStamp = (time: string): string => {
  const today = moment().unix();
  const ts = moment(time).unix();
  const cal = today - ts;

  const min = cal / 60;
  const hour = min / 60;
  const day = hour / 24;
  const month = day / 30;
  const year = month / 12;

  if (min < 1) return '방금';
  if (min < 60) return `${Math.floor(min).toString()}분 전`;
  if (hour < 24) return `${Math.floor(hour).toString()}시간 전`;
  if (day < 30) return `${Math.floor(day).toString()}일 전`;
  if (month < 12) return `${Math.floor(month).toString()}달 전`;
  return `${Math.floor(year).toString()}년 전`;
};
